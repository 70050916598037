import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Menu from "./components/Menu";
import Topheader from "./components/Topheader";
import Contact from "./components/Contact";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Pricing from "./components/Pricing";
import Gallery from "./components/Gallery";
import Orders from "./components/Orders";
import Embroidery from "./components/Embroidery";
import Vector from "./components/Vector";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Topheader /> */}
        <Header />
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/embroidery" element={<Embroidery />} />
          <Route path="/vector" element={<Vector />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
