import React, { useState } from "react";

function Orders() {
  const [formData, setFormData] = useState({
    username: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    orderType: "",
    turnaroundTime: "",
    designName: "",
    requiredFormat: "",
    fabric: "",
    width: "",
    measurement: "",
    placement: "",
    height: "",
    sizeProportion: "",
    specialtyElements: "",
    numberOfColors: "",
    fileName: "",
    file: null,
    message: "",
  });

  const [sending, setSending] = useState(false); // State for managing sending message button

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file size exceeds 4MB
      if (file.size > 4 * 1024 * 1024) {
        // 4MB in bytes
        alert("File size exceeds 4MB. Please choose a smaller file.");
        e.target.value = ""; // Reset the file input so no file is selected
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          file: reader.result, // Base64 string
          fileName: file.name, // File name
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      const response = await fetch("https://api.way2digitizing.com/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSending(false);
        alert(
          "Thank you for reaching out for order. We will get back to you shortly."
        );
        // Optionally reset form
        setFormData({
          username: "",
          companyName: "",
          email: "",
          phoneNumber: "",
          orderType: "",
          turnaroundTime: "",
          designName: "",
          requiredFormat: "",
          fabric: "",
          width: "",
          measurement: "",
          placement: "",
          height: "",
          sizeProportion: "",
          specialtyElements: "",
          numberOfColors: "",
          fileName: "",
          file: null,
          message: "",
        });
      } else {
        setSending(false);
        alert("Failed to send email. Please try again.");
        // Handle error
      }
    } catch (error) {
      setSending(false);
      alert("Failed to send email. Please try again.");
      // Handle error
    }

    // // Create a FormData object
    // const formDataToSend = new FormData();
    // for (const key in formData) {
    //   if (formData[key] !== null && formData[key] !== "") {
    //     formDataToSend.append(key, formData[key]);
    //   }
    // }

    // try {
    //   const response = await fetch("http://localhost:8000/order", {
    //     method: "POST",
    //     body: formDataToSend,
    //   });

    //   if (response.ok) {
    //     alert("Form submitted successfully!");
    //   } else {
    //     alert("Form submission failed!");
    //   }
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    //   alert("An error occurred while submitting the form.");
    // }
  };

  return (
    <div>
      {/* Banner Start */}
      <div className="hm_bread_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>Place an Order</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Orders</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* Blog Section Start */}
      <div className="hm_news_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {/* Comment Form */}
              <div className="comment-form">
                <div className="group-title">
                  <h2>PLACE AN ORDER</h2>
                </div>
                {/* Comment Form */}
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="username"
                        placeholder="Your Name"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="companyName"
                        placeholder="Company Name"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="orderType"
                        value={formData.orderType}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Order Type
                        </option>
                        <option value="Embroidery">Embroidery</option>
                        <option value="Raster to Vector">
                          Raster to Vector
                        </option>
                        <option value="Quote">Quote</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="turnaroundTime"
                        value={formData.turnaroundTime}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Turnaround Time
                        </option>
                        <option value="Normal (within 12 hours)">
                          Normal (within 12 hours)
                        </option>
                        <option value="Rush (within 2 hours at extra $3 Per Job)">
                          Rush (within 2 hours at extra $3 Per Job)
                        </option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="designName"
                        placeholder="Design Name"
                        value={formData.designName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="requiredFormat"
                        value={formData.requiredFormat}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Required Format
                        </option>
                        <option value="100">100</option>
                        <option value="cdr">cdr</option>
                        <option value="cnd">cnd</option>
                        <option value="dsb">dsb</option>
                        <option value="dst">dst</option>
                        <option value="dsz">dsz</option>
                        <option value="emb">emb</option>
                        <option value="exp">exp</option>
                        <option value="jef">jef</option>
                        <option value="ksm">ksm</option>
                        <option value="pes">pes</option>
                        <option value="pof">pof</option>
                        <option value="tap">tap</option>
                        <option value="xxx">xxx</option>
                        <option value="pxf">pxf</option>
                        <option value="sus">sus</option>
                        <option value="hus">hus</option>
                        <option value="ngs">ngs</option>
                        <option value="others">others</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="fabric"
                        value={formData.fabric}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Fabric
                        </option>
                        <option value="Apron">Apron</option>
                        <option value="Blanket">Blanket</option>
                        <option value="Canvas">Canvas</option>
                        <option value="Cotton Woven">Cotton Woven</option>
                        <option value="Chenille">Chenille</option>
                        <option value="Denim">Denim</option>
                        <option value="Felt">Felt</option>
                        <option value="Fleece">Fleece</option>
                        <option value="Flannel">Flannel</option>
                        <option value="Pique">Pique</option>
                        <option value="Single Jersey">Single Jersey</option>
                        <option value="Silk">Silk</option>
                        <option value="Polyester">Polyester</option>
                        <option value="knit sweater">knit sweater</option>
                        <option value="Twill">Twill</option>
                        <option value="Towel">Towel</option>
                        <option value="Leather">Leather</option>
                        <option value="Nylon">Nylon</option>
                        <option value="polar fleece">polar fleece</option>
                        <option value="Mesh knit">Mesh knit</option>
                        <option value="Beanie">Beanie</option>
                        <option value="Stretchy polyester">
                          Stretchy polyester
                        </option>
                        <option value="Light Knit Fabric">
                          Light Knit Fabric
                        </option>
                        <option value="Marine Grade Vinyl">
                          Marine Grade Vinyl
                        </option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="width"
                        placeholder="Width"
                        value={formData.width}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="measurement"
                        value={formData.measurement}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Measurement
                        </option>
                        <option value="Inches">Inches</option>
                        <option value="Centimeters">Centimeters</option>
                        <option value="Milliliters">Milliliters</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="placement"
                        value={formData.placement}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Design Placement
                        </option>
                        <option value="HAT FRONT - CENTER">
                          HAT FRONT - CENTER
                        </option>
                        <option value="HAT FRONT - LEFT SIDE">
                          HAT FRONT - LEFT SIDE
                        </option>
                        <option value="HAT FRONT - RIGHT SIDE">
                          HAT FRONT - RIGHT SIDE
                        </option>
                        <option value="LEFT/RIGHT CHEST">
                          LEFT/RIGHT CHEST
                        </option>
                        <option value="JACKET BACK">JACKET BACK</option>
                        <option value="SLEEVE">SLEEVE</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="height"
                        placeholder="Height"
                        value={formData.height}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="sizeProportion"
                        value={formData.sizeProportion}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Size Proportion
                        </option>
                        <option value="Make the width as per my request and keep height proportional.">
                          Make the width as per my request and keep height
                          proportional.
                        </option>
                        <option value="Make the height as per my request and keep width proportional.">
                          Make the height as per my request and keep width
                          proportional.
                        </option>
                        <option value="Make both height and width as per my request.">
                          Make both height and width as per my request.
                        </option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <select
                        name="specialtyElements"
                        value={formData.specialtyElements}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Specialty Elements
                        </option>
                        <option value="3D Puff - Motifs/Chain Stitch/Serpent effect (please specify 3D Puff details in notes below)">
                          3D Puff - Motifs/Chain Stitch/Serpent effect (please
                          specify 3D Puff details in notes below)
                        </option>
                        <option value="None (standard embroidery)">
                          None (standard embroidery)
                        </option>
                        <option value="Applique - Placement / Tackdown">
                          Applique - Placement / Tackdown
                        </option>
                        <option value="Applique - Hand Cut">
                          Applique - Hand Cut
                        </option>
                        <option value="Applique - Plotter cut laser">
                          Applique - Plotter cut laser
                        </option>
                        <option value="Faux Merrow Specialty Patch Border">
                          Faux Merrow Specialty Patch Border
                        </option>
                        <option value="Hand Stitch ( Design Look )">
                          Hand Stitch ( Design Look )
                        </option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 form-group">
                      <input
                        type="text"
                        name="numberOfColors"
                        placeholder="Number of Colors"
                        value={formData.numberOfColors}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 form-group">
                      <input
                        type="file"
                        name="file"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 form-group">
                      <textarea
                        name="message"
                        placeholder="Additional Info"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      {sending ? (
                        <button type="button" className="hm_btn" disabled>
                          Sending message...
                        </button>
                      ) : (
                        <button type="submit" className="hm_btn">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
