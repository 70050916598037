import React, { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",  // Changed from emailid to email
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [sending, setSending] = useState(false); // State for managing sending message button
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      const response = await fetch("https://api.way2digitizing.com/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSending(false);
        alert("Thank you for contacting us. We will get back to you shortly.");
        // Optionally reset form
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
      } else {
        setSending(false);
        alert("Failed to send email. Please try again.");
        // Handle error
      }
    } catch (error) {
      setSending(false);
      alert("Failed to send email. Please try again.");
      // Handle error
    }
  };

  return (
    <>
      {/* Banner start */}
      <div className="hm_bread_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>Contact</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">Contact</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* About section start */}
      <div className="contact_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_box">
                <i className="fa fa-envelope" aria-hidden="true" />
                <h2>Email</h2>
                <p>
                  <a href="mailto:info@way2digitizing.com">
                    info@way2digitizing.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_box">
                <i className="fa fa-phone" aria-hidden="true" />
                <h2>Phone</h2>
                <p>+91 000 000 000</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_box">
                <i className="fa fa-map-marker" aria-hidden="true" />
                <h2>Address</h2>
                <p>665 Clinton Lane, Wilkes Barre, PA 18702</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact form wrapper */}
      <div className="contact_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 padding_left padding_right">
              <div className="contact_map">
                <div id="contact_map" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 padding_right padding_left">
              <div className="hm_form">
                <div className="col-lg-10 col-md-12">
                  <h1>Send a Message</h1>
                  <form className="form-inline" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Phone"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group textarea">
                      <textarea
                        className="form-control"
                        rows={3}
                        id="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="con_btn">
                    {sending ? (
                        <button type="button" className="hm_btn submit_frm" disabled>
                          Sending message...
                        </button>
                      ) : (
                        <button type="submit" className="hm_btn submit_frm">
                          Submit
                        </button>
                      )}
                    </div>
                    <p id="err_msg" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
