// signupForm.js
export function initializeSignupForm() {
    const popupSignupForm = document.querySelector('.popup-signup-form');
    const signupFormOverlay = document.querySelector('.hm_signup_form_overlay');
    const loginClose = document.querySelector('.login_close');
    const signupForm = document.querySelector('.hm_signup_form');
  
    if (popupSignupForm) {
      popupSignupForm.addEventListener('click', function() {
        signupFormOverlay.classList.add('login_open');
        document.body.classList.add('login_overflow');
      });
    }
  
    if (loginClose) {
      loginClose.addEventListener('click', function() {
        signupFormOverlay.classList.remove('login_open');
        document.body.classList.remove('login_overflow');
      });
    }
  
    if (signupFormOverlay) {
      signupFormOverlay.addEventListener('click', function() {
        signupFormOverlay.classList.remove('login_open');
        document.body.classList.remove('login_overflow');
      });
    }
  
    if (signupForm) {
      signupForm.addEventListener('click', function(event) {
        event.stopPropagation(); // Prevent closing the form when clicking inside the form
      });
    }
  }
  