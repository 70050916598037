import React, {useEffect, useState } from 'react'
import { initializeSignupForm } from './utility/custom'; // Adjust the path as necessary
function Header() {
  useEffect(() => {
    // Initialize the signup form interactions
    initializeSignupForm();
  }, []);

  const [formData, setFormData] = useState({
    uname: "",
    phone: "",
    email: "",
    fileName: "",
    file: null,
    message: ""
  });

  const [sending, setSending] = useState(false); // State for managing sending message button

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      // Check if the file size exceeds 4MB
      if (file.size > 4 * 1024 * 1024) { // 4MB in bytes
        alert("File size exceeds 4MB. Please choose a smaller file.");
        e.target.value = "";  // Reset the file input so no file is selected
        return;
      }
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          file: reader.result, // Base64 string
          fileName: file.name   // File name
        });
      };
      reader.readAsDataURL(file);
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    fetch("https://way2digitizing.com/server/quote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.json())
      .then((data) => {
        setSending(false);
        if (data.message === "Email sent successfully!") {
          alert("Thank you for reaching out to us for a free quotation! We will get back to you shortly.");
          setFormData({
            uname: "",
            phone: "",
            email: "",
            file: null,
            fileName: "",
            message: ""
          });
  
           // Simulate a click on the .login_close element to close the popup
        document.querySelector(".login_close").click();
        } else {
          setSending(false);
          alert("Failed to send email. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
     {/*Header start*/}
<div className="hm_header_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-8 col-md-8 col-sm-6">
        <div className="hm_logo">
          <a href="/"><img src="assets/images/logo.png" alt="handmade-craft-logo" className="img-responsive" style={{width:139}} /></a>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="hm_top_Search_main">
        
          <div className="hm_top_search">
            {/* <input type="search" name="search"  placeholder='Free Quote' /> */}
            <a href="javascript:;" class="hm_btn popup-signup-form">Free Quote</a>
            {/* <a href="javascript:;" class="popup-signup-form"> */}
            {/* <span className="search_icon"><i className="fa fa-search" aria-hidden="true" /></span> */}
            {/* </a> */}
          </div>
          {/* <div className="menu_btn"><span /><span /><span /></div> */}
        </div>
        
      </div>
      
    </div>
  </div>
  <div className="hm_header_overlay" />
</div>
{/*popup signup form*/}
<div className="hm_signup_form_overlay">
          <div className="hm_signup_form">
            <div className="hm_login_header">
              <h1>Free Quote</h1>
              <span className="login_close">
                <i className="fa fa-times" aria-hidden="true" />
              </span>
            </div>
            <div className="login_fields">
            <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="uname"
                placeholder="Name"
                value={formData.uname}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="form-group" >
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="file"
                className="form-control"
                onChange={handleFileChange}
              />
              <span>Note: Maximum file size 4MB</span>
            </div>
            <div className="form-group">
              <textarea
                cols={62}
                id="message"
                name="message"
                placeholder="Comments"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className="form_btn">
                {sending ? (
                  <button type="button" className="hm_btn" disabled>
                    Sending message...
                  </button>
                ) : (
                  <button type="submit" className="hm_btn">
                    Send
                  </button>
                )}
              </div>
          </form>
            </div>
          </div>
        </div>
    </>
  )
}

export default Header
