import React from "react";
import Topheader from "./Topheader";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";

function Pricing() {
  return (
    <>
    
      {/*banner start*/}
      <div className="hm_bread_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>Pricing</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item ">Pricing</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-4 col-sm-12">
              <div className="contact_box">
                
                <h2>Embroidery Digitizing</h2>
                <p>
                <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ textAlign: 'left' }}>Cap or Left chest up to 6"</td>
          <td style={{ textAlign: 'left' }}>$7</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>Jacket Backs or Design Over 6"</td>
          <td style={{ textAlign: 'left' }}>$15 to $30</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>Minor Changes</td>
          <td style={{ textAlign: 'left' }}>Free</td>
        </tr>
        {/* <tr>
          <td style={{ textAlign: 'left' }}>Format Conversion</td>
          <td style={{ textAlign: 'left' }}>Free</td>
        </tr> */}
        <tr>
          <td style={{ textAlign: 'left' }}>Standard Turnaround Time:</td>
          <td style={{ textAlign: 'left' }}>Within 4 to 12 hours</td>
        </tr>
      </tbody>
    </table>
                </p>
                
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12">
              <div className="contact_box">
                <h2>Vector Art</h2>
                <p><table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ textAlign: 'left' }}>Simple Artwork</td>
          <td style={{ textAlign: 'left' }}>$7</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>
            Complex Artwork (Depending on art)
          </td>
          <td style={{ textAlign: 'left' }}>$15 to $30</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>Minor Changes</td>
          <td style={{ textAlign: 'left' }}>Free</td>
        </tr>
       {/* <tr>
           <td style={{ textAlign: 'left' }}>Format Conversion</td>
          <td style={{ textAlign: 'left' }}>Free</td>
        </tr> */}
        <tr>
          <td style={{ textAlign: 'left' }}>Standard Turnaround Time:</td>
          <td style={{ textAlign: 'left' }}>Within 6 to 12 hours</td>
        </tr>
      </tbody>
    </table> </p>
                
              </div>
            </div>
           
          </div>
        </div>
      </div>
      {/* <div className="hm_arrivals_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">Our</span> Pricing
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_data">
                  <h3>
                    <a href="#">Embroidery Digitizing</a>
                  </h3>
                  <p>Cap or Left chest upto 6"    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $7</p>
                  <p>Jacket Backs or Design (Over 6") &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $15 to $30 </p>
                  <p>Edits &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free</p>
                  <p>Format Conversion &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free</p>
                  <p>standard Turnaround Time: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Within 4 to 12</p>
                </div>
              </div>
            </div> */}
            {/*2*/}
            {/* <div className="col-lg-6 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_data">
                  <h3>
                    <a href="#">Vector Art</a>
                  </h3>
                  <p>
                    Simple Artwork
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    $7
                  </p>
                  <p>
                    Complex Artwork (Depending on art)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    $15 to $30
                  </p>
                  <p>
                    Edits
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Free
                  </p>
                  <p>
                    Format Conversion
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Free
                  </p>
                  <p>
                    Standard Turnaround time:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Within 6 to 12
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Pricing;
