import React from 'react'

function Footer() {
  return (
    <>
     <div>
  {/*footer start*/}
  {/* <div className="hm_footer_wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="hm_footer_box">
            <img src="assets/images/logo.png" alt="handmade-craft-logo" className="img-responsive" />
            <p className="footer_about_para">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="hm_footer_box">
            <h3 className="footer_heading">Help</h3>
            <ul>
              <li><a href="#">Find Your Beer</a></li>
              <li><a href="#">Customer Service</a></li>
              <li><a href="#">Contact</a></li>
              <li><a href="#">Recent Orders</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="hm_footer_box">
            <h3 className="footer_heading">links</h3>
            <ul>
              <li><a href="index.html">home</a></li>
              <li><a href="shop.html">shop</a></li>
              <li><a href="404.html">404 page</a></li>
              <li><a href="javascript:;">login</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="hm_footer_box hm_footer_contact">
            <h3 className="footer_heading">Our Information</h3>
            <ul>
              <li>
                <div className="hm_footer_information">
                  <span className="footer_icon"><i className="fa fa-map-marker" aria-hidden="true" /> </span>
                  <p className="foo_con_para">787 Lakeview St. Marion, NC 28752</p>
                </div>
              </li>
              <li>
                <div className="hm_footer_information"><span className="footer_icon"><i className="fa fa-phone" aria-hidden="true" /> </span>
                  <p className="foo_con_para">+1800123654789 +1800123456788</p></div>
              </li>
              <li>
                <div className="hm_footer_information">
                  <a href="mailto:jhini.mehta@gmail.com"><span className="footer_icon"><i className="fa fa-envelope" aria-hidden="true" /> </span><p className="foo_con_para">support@handmade.net</p></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/*bottom footer start*/}
  <div className="hm_bottom_footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <p>Copyrights © 2024 All Rights Reserved by way2digitizing</p>
        </div>
        <div className="col-lg-6 col-md-12">
          <ul className="footer_so_icons">
            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true" /></a></li>
            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
            {/* <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true" /></a></li> */}
            {/* <li><a href="#"><i className="fa fa-pinterest-p" aria-hidden="true" /></a></li> */}
            <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  
</div>

    </>
  )
}

export default Footer
