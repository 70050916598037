import React from 'react'

function Embroidery() {
  return (
    <div>
    {/*banner start*/}
    <div className="hm_bread_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h1>Embroidery Digitizing</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">Home</a>
              </li>
              <li className="breadcrumb-item active">Embroidery Digitizing</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    {/*About section start*/}
    <div className="hm_gallery_wrapper">
      <div className="container">
        <div className="hm_gallery_inner">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="gallery_img">
                    <img
                      src="assets/images/gallery1.jpg"
                      alt
                      className="img-fluid"
                    />
                    <div className="gallery_img_overlay" />
                    <div className="overlay_buttons">
                      <a
                        className="popup-gallery"
                        href="assets/images/gallery_big1.jpg"
                      >
                        <i className="fa fa-search" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="gallery_img">
                    <img
                      src="assets/images/gallery2.jpg"
                      alt
                      className="img-fluid"
                    />
                    <div className="gallery_img_overlay" />
                    <div className="overlay_buttons">
                      <a
                        className="popup-gallery"
                        href="assets/images/gallery_big2.jpg"
                      >
                        <i className="fa fa-search" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="gallery_img">
                <img
                  src="assets/images/gallery3.jpg"
                  alt
                  className="img-fluid"
                />
                <div className="gallery_img_overlay" />
                <div className="overlay_buttons">
                  <a
                    className="popup-gallery"
                    href="assets/images/gallery_big3.jpg"
                  >
                    <i className="fa fa-search" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="gallery_img">
                <img
                  src="assets/images/gallery4.jpg"
                  alt
                  className="img-fluid"
                />
                <div className="gallery_img_overlay" />
                <div className="overlay_buttons">
                  <a
                    className="popup-gallery"
                    href="assets/images/gallery_big4.jpg"
                  >
                    <i className="fa fa-search" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="gallery_img">
                    <img
                      src="assets/images/gallery5.jpg"
                      alt
                      className="img-fluid"
                    />
                    <div className="gallery_img_overlay" />
                    <div className="overlay_buttons">
                      <a
                        className="popup-gallery"
                        href="assets/images/gallery_big5.jpg"
                      >
                        <i className="fa fa-search" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="gallery_img">
                    <img
                      src="assets/images/gallery6.jpg"
                      alt
                      className="img-fluid"
                    />
                    <div className="gallery_img_overlay" />
                    <div className="overlay_buttons">
                      <a
                        className="popup-gallery"
                        href="assets/images/gallery_big6.jpg"
                      >
                        <i className="fa fa-search" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="gallery_img">
                <img
                  src="assets/images/gallery7.jpg"
                  alt
                  className="img-fluid"
                />
                <div className="gallery_img_overlay" />
                <div className="overlay_buttons">
                  <a
                    className="popup-gallery"
                    href="assets/images/gallery_big7.jpg"
                  >
                    <i className="fa fa-search" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="gallery_img">
                <img
                  src="assets/images/gallery8.jpg"
                  alt
                  className="img-fluid"
                />
                <div className="gallery_img_overlay" />
                <div className="overlay_buttons">
                  <a
                    className="popup-gallery"
                    href="assets/images/gallery_big8.jpg"
                  >
                    <i className="fa fa-search" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Embroidery
