import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Menu() {
  const location = useLocation();
  
  return (
    <>
      {/* Menu Start */}
      <div className="hm_menu_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="hm_menu_wrap">
                <nav>
                  <div className="hm_menu">
                    <ul>
                      <li className={location.pathname === "/" ? "active dropdown" : ""}>
                        <a href="/">Home</a>
                      </li>
                      
                      <li className={location.pathname === "/about" ? "active dropdown" : ""}>
                        <Link to="/about">About</Link>
                      </li>
                      <li class={location.pathname === "/embroidery" || location.pathname === "/vector" ? "active dropdown" : ""}><a href="javacsript:;">work</a>
											<ul class="sub-menu">
												<li><Link to="/embroidery">Embroidery Digitizing</Link></li>
												<li><Link to="/vector">Vector Art</Link></li>
											</ul>
										</li>
                      <li className={location.pathname === "/pricing" ? "active dropdown" : ""}>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li className={location.pathname === "/faq" ? "active dropdown" : ""}>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li className={location.pathname === "/contact" ? "active dropdown" : ""}>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li className={location.pathname === "/orders" ? "active dropdown" : ""}>
                        <Link to="/orders">place an order</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Menu End */}
    </>
  );
}

export default Menu;
