import React from 'react'
import { Link } from 'react-router-dom'

function FAQ() {
  return (
    <>
      {/*banner start*/}
      <div className="hm_bread_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>FAQ</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item ">FAQ</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  {/*Choose section start*/}
  <div className="hm_choose_wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-12">
        </div>
        <div className="col-lg-8 col-md-12 pull-right padding_left">
          <div className="hm_choose_data">
            <h1 className="hm_heading text-left"><span className="hm_color">General </span>Questions</h1>
            <div className="hm_choose_list">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      <h4>How to place Order ?</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">You can e-mail us the images and other details of your requirements. Please remember to enclose below information when you send us your design.<br />
                    a. Provide contact info (name, email & contact number).<br />
b. Size (width and height).<br />
c. Material Fabric)<br />
d. Format<br />
e. Design Placement<br />
</div>
                  </div>
                </div>
                {/*  */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      <h4>What is your delivery time/ turnaround time ?</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Normally we deliver in 4 to 24 hours, but we can reduce the delivery time for special requests.</div>
                  </div>
                </div>
               
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      <h4>Do your provide estimate ?
</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Yes, we provide free estimate within 4 to 12 hours.
</div>
                  </div>
                </div>
                {/*  */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      <h4>What artwork format do you accept ?
</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"> Any format. We can read all of the following formats - .jpeg .tif .gif .pcx .cdr .cpt .ai .eps .pdf .wmf .bmp. 				
<br />These are the most common formats but if you have a format not listed here send it anyway we are pretty good at this.				
 </div>
                  </div>
                </div>
                
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSeven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                      <h4>What format can you deliver my designs?
</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                    
                    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Embroidery Digitizing</td>
          <td style={{ textAlign: 'left', fontWeight: 'bold' }}></td>
          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Vector art</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>Tajima .dst</td>
          <td style={{ textAlign: 'left' }}>Barudan .fmc</td>
          <td style={{ textAlign: 'center' }}>.ai</td>
        </tr>
        <tr>
        <td style={{ textAlign: 'left' }}>Melco .ofm</td>
        <td style={{ textAlign: 'left' }}>Melco .dos/exp</td>
          
          <td style={{ textAlign: 'center' }}>.pdf</td>
        </tr>
        <tr>
        <td style={{ textAlign: 'left' }}>Melco .cnd</td>
        <td style={{ textAlign: 'left' }}>Compucon .ref</td>
          <td style={{ textAlign: 'center' }}>.jpg</td>
        </tr>
        <tr>
        <td style={{ textAlign: 'left' }}>Wilcom .emb, .dst</td>
        <td style={{ textAlign: 'left' }}>Pulse .pxf</td>
          
          
          <td style={{ textAlign: 'center' }}>.eps</td>
        </tr>
        <tr>
        <td style={{ textAlign: 'left' }}>Ehos .isi</td>
        <td style={{ textAlign: 'left' }}>Toyota .puff/.zsk</td>
         
          <td style={{ textAlign: 'center' }}></td>
        </tr>
        <tr>
        <td style={{ textAlign: 'left' }}>Wings .mls</td>

          <td style={{ textAlign: 'left' }}>Brother .pes</td>
          <td style={{ textAlign: 'center' }}></td>
        </tr>
      </tbody>
    </table>





 </div>
                  </div>
                </div>
                {/*  */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingEight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                      <h4>How will I receive my digitize files?	
</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">You will receive your files by email..All designs are sent as attachments through a secured server.		
</div>
                  </div>
                </div>
                
                
                {/*  */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwelve">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                      <h4>How do I pay?</h4>
                    </button>
                  </h2>
                  <div id="flush-collapseTwelve" className="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"> We accept payment by Visa/MasterCard/Paypal and Wire Transfer.
</div>
                  </div>
                </div>
                {/*  */}
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</>

  )
}

export default FAQ
