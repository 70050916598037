import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSnowflake,
  faPaperPlane,
  faMedal,faRightLong
} from "@fortawesome/free-solid-svg-icons";
import OurWorks from "./OurWorks";
function Home() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",  // Changed from emailid to email
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [sending, setSending] = useState(false); // State for managing sending message button
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      const response = await fetch("https://api.way2digitizing.com/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSending(false);
        alert("Thank you for contacting us. We will get back to you shortly.");
        // Optionally reset form
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
      } else {
        setSending(false);
        alert("Failed to send email. Please try again.");
        // Handle error
      }
    } catch (error) {
      setSending(false);
      alert("Failed to send email. Please try again.");
      // Handle error
    }
  };
  return (
    <>
      {/* Banner Start */}
      <div className="banner-container" style={{ position: "relative" }}>
        <img
          src="assets/images/slide1.jpeg"
          alt="handmade-craft-slide1"
          data-lazyload="./images/slide1.jpeg"
          data-bgposition="center center"
          data-kenburns="on"
          data-duration={20000}
          data-ease="Power1.easeOut"
          data-scalestart={110}
          data-scaleend={100}
          data-rotatestart={0}
          data-rotateend={0}
          data-offsetstart="0 0"
          data-offsetend="0 0"
          className="rev-slidebg"
          data-no-retina
          style={{ width: "100%", height: "600px" }}
        />
        {/* Text and Content Overlay */}
        <div
          className="banner-content"
          style={{
            position: "absolute",
            top: "30%", // Adjust to move it vertically on the image
            left: "50%",
            transform: "translateX(-50%)", // Center horizontally

            textAlign: "center", // Align text in the center
          }}
        >
          <h1>
            <b style={{ color: "white" }}>
              EMBROIDERY DIGITIZING AND VECTOR ART
            </b>
          </h1>
          <br />
          <h5 style={{ color: "white" }}>
            Transform your designs into high-quality embroidery with precision
            digitizing services
          </h5>

          {/* List Topics Content */}
          <div className="contact_wrapper_Box">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div
                    className="contact_box"
                    style={{ background: "white", border: "none" }}
                  >
                    <p>
                      <FontAwesomeIcon icon={faSnowflake} size="3x" /> <br />
                      <br />
                      Digitizing
                    </p>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12">
                  <div
                   
                    style={{     color: "#fc5a6d", border: "none", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <p>
                      <FontAwesomeIcon icon={faRightLong} size="4x" /> <br />
                   
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div
                    className="contact_box"
                    style={{ background: "white", border: "none" }}
                  >
                    <p>
                      <FontAwesomeIcon icon={faMedal} size="3x" /> <br />
                      <br />
                      Qualit Check
                    </p>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12">
                  <div
                   
                    style={{     color: "#fc5a6d", border: "none", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <p>
                      <FontAwesomeIcon icon={faRightLong} size="4x" /> <br />
                   
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div
                    className="contact_box"
                    style={{ background: "white", border: "none" }}
                  >
                    <p>
                      <FontAwesomeIcon icon={faPaperPlane} size="3x" /> <br />
                      <br />
                      Final To Send
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/*About section start*/}
      <div className="hm_about_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="hm_about_img">
                <img
                  src="assets/images/about.jpg"
                  alt="handmade-craft-about"
                  className="img-responsive"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="hm_about_data">
                <h1>
                  <span className="hm_color">Welcome</span> To Way2digitizing!
                </h1>
                <p>
                  Way2Digitizing specializes in transforming your creative
                  visions into digital masterpieces. Our mission is to deliver
                  high-quality embroidery digitizing and vector art services
                  that bring your designs to life, whether for embroidery,
                  screen printing, or other digital formats.
                </p>
                <p>
                  Based in India, Way2Digitizing boasts over 10 years of
                  experience in embroidery digitizing and graphic design. We are
                  dedicated to providing top-notch embroidery digitizing and
                  vector art services worldwide.
                </p>

                <div className="hm_btn_wrapper">
                  <Link to="about" className="hm_btn">
                    read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/*our artist section start*/}

      {/* <div className="hm_artist_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">Our </span> Services
                
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-12 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/sewing-machine.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                  <h2>Embroidery Digitizing</h2>
                    <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source."
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12">
              <div className="">
                
                  
                    <div className="artist_img">
                      <img
                        src="assets/images/blog_big.jpg"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        width={"100%"}
                        height={"100%"}
                      />
                    
                  

                  
                </div>

                
              </div>
            </div>
          </div>
          
        </div>
      </div> */}

      {/*happy customer sevtion start*/}



 


      {/* <div className="hm_artist_wrapper" style={{backgroundColor: "#f5f5f5"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">About </span> Us
              </h1>
            </div>
          </div>
          <div class="row"><div class="col-lg-12 col-md-12"><div class="hm_about_data"><p>Way2digitizing was founded in April 2010 as a Embroidery Digitizing and Vector Art services. Currently, our team comprises 25 full-time digitizers and 5 Quality Assistant with the capacity to handle approximately 100 designs per day.</p><p>Our company specializes in embroidery and graphic digitizing, offering highly experienced custom embroidery and digitizing services. We are committed to focusing our efforts on achieving the highest level of customer satisfaction .</p></div></div></div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/bestquality.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <b><p>Best Quality</p></b><br></br>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As an embroidery digitizer with Way2Digitizing, I take
                      immense pride in transforming intricate designs into
                      high-quality embroidery files. My goal is to ensure that
                      every stitch, color, and detail is perfectly translated
                      from the digital file to the fabric.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/bestprice.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <b><p>Best Price</p></b><br></br>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As an embroidery digitizer with Way2Digitizing, I take
                      immense pride in transforming intricate designs into
                      high-quality embroidery files. My goal is to ensure that
                      every stitch, color, and detail is perfectly translated
                      from the digital file to the fabric.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/Customersupport.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12"><br></br>
                    <b><p>Customer Support</p></b><br></br>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As an embroidery digitizer with Way2Digitizing, I take
                      immense pride in transforming intricate designs into
                      high-quality embroidery files. My goal is to ensure that
                      every stitch, color, and detail is perfectly translated
                      from the digital file to the fabric.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>



          </div>
         
        </div>
      </div> */}

      {/* <div className="hm_artist_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">Our Expert Digitizer </span> and
                Quality Assistant
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/male.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Devesh Mourya</h2>
                    <h5>Senior Digitizer</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As an embroidery digitizer with Way2Digitizing, I take
                      immense pride in transforming intricate designs into
                      high-quality embroidery files. My goal is to ensure that
                      every stitch, color, and detail is perfectly translated
                      from the digital file to the fabric.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/male.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Aditya Kumar</h2>
                    <h5>Senior Digitizer</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      We utilize the latest technology and techniques to ensure
                      the best results, and I am dedicated to working closely
                      with our clients to bring their vision to life. Whether
                      it’s a complex logo or a delicate artwork, I’m committed
                      to delivering exceptional quality and helping our clients
                      stand out with beautifully crafted embroidered pieces.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/male.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Azaan D'souza</h2>
                    <h5>Senior Art Designer</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      At way2digitizing, my role as an expert designer is to
                      transform our clients’ concepts into precise, versatile
                      vector artwork that stands out. I approach each project
                      with a focus on both creative flair and technical
                      accuracy, My goal is to deliver vector art that captures
                      the essence of the client’s vision while maintaining the
                      highest standards of quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/female.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "160px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Bhakshi Batra</h2>
                    <h5>Quality Assistant</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As a Quality Assistant at way2digitizing, my primary focus
                      is to uphold the highest standards of accuracy and
                      craftsmanship in every embroidery digitizing project we
                      handle. I meticulously review each design file to ensure
                      that it meets our stringent quality criteria, from stitch
                      density and alignment to color accuracy and fabric
                      compatibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      

      {/*New Arrivals section start*/}
      <div className="hm_arrivals_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">Our</span> Services
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_img">
                  <img
                    src="assets/images/new_pro1.jpg"
                    alt="handmade-craft-arrivals1"
                    className="img-fluid"
                  />
                </div>
                <div className="new_pro_data">
                  <h3>
                    <a href="#">Reguler Embroidery.</a>
                  </h3>
                </div>
              </div>
            </div>
            {/*2*/}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_data">
                  <h3>
                    <a href="shop-single.html">3d Puff Embroidery.</a>
                  </h3>
                </div>
                <div className="new_pro_img">
                  <img
                    src="assets/images/new_pro2.jpg"
                    alt="handmade-craft-arrivals2"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            {/*3*/}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_img">
                  <img
                    src="assets/images/new_pro3.jpg"
                    alt="handmade-craft-arrivals3"
                    className="img-fluid"
                  />
                </div>
                <div className="new_pro_data">
                  <h3>
                    <a href="shop-single.html">Applique Embroidery.</a>
                  </h3>
                </div>
              </div>
            </div>
            {/*4*/}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_img">
                  <img
                    src="assets/images/new_pro4.jpg"
                    alt="handmade-craft-arrivals4"
                    className="img-fluid"
                  />
                </div>
                <div className="new_pro_data">
                  <h3>
                    <a href="shop-single.html">Patch Embroidery</a>
                  </h3>
                </div>
              </div>
            </div>
            {/*5*/}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_data">
                  <h3>
                    <a href="shop-single.html">Jacket back embroidery.</a>
                  </h3>
                </div>
                <div className="new_pro_img">
                  <img
                    src="assets/images/new_pro5.jpg"
                    alt="handmade-craft-arrivals5"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            {/*6*/}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="new_pro_box">
                <div className="new_pro_img">
                  <img
                    src="assets/images/new_pro6.jpg"
                    alt="handmade-craft-arrivals6"
                    className="img-fluid"
                  />
                </div>
                <div className="new_pro_data">
                  <h3>
                    <a href="shop-single.html">Raster to Vector.</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Choose section start*/}

      
      
      <div className="hm_choose_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12"></div>
            <div className="col-lg-8 col-md-12 pull-right padding_left">
              <div className="hm_choose_data">
                <h1 className="hm_heading text-left">
                  <span className="hm_color">why </span>Choose Us
                </h1>
                <div className="hm_choose_list">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button show"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="true"
                          aria-controls="flush-collapseOne"
                        >
                          <h4>Expertise</h4>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Our team has extensive experience in the digitizing
                          industry, ensuring top-notch results every time.
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <h4>Quality</h4>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          We use the latest technology and techniques to deliver
                          designs that meet the highest standards
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <h4>Customer-Centric Approach</h4>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          We prioritize your needs and work closely with you to
                          bring your vision to life.
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <h4>Timely Delivery</h4>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          We understand the importance of deadlines and strive
                          to deliver your projects promptly.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*our artist section start*/}

      

      <div className="hm_artist_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">Our Expert Digitizer </span> and
                Quality Assistant
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/male.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Devesh Mourya</h2>
                    <h5>Senior Digitizer</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As an embroidery digitizer with Way2Digitizing, I take
                      immense pride in transforming intricate designs into
                      high-quality embroidery files. My goal is to ensure that
                      every stitch, color, and detail is perfectly translated
                      from the digital file to the fabric.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/male.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Aditya Kumar</h2>
                    <h5>Senior Digitizer</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      We utilize the latest technology and techniques to ensure
                      the best results, and I am dedicated to working closely
                      with our clients to bring their vision to life. Whether
                      it’s a complex logo or a delicate artwork, I’m committed
                      to delivering exceptional quality and helping our clients
                      stand out with beautifully crafted embroidered pieces.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/male.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "100px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Azaan D'souza</h2>
                    <h5>Senior Art Designer</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      At way2digitizing, my role as an expert designer is to
                      transform our clients’ concepts into precise, versatile
                      vector artwork that stands out. I approach each project
                      with a focus on both creative flair and technical
                      accuracy, My goal is to deliver vector art that captures
                      the essence of the client’s vision while maintaining the
                      highest standards of quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact_box">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="artist_img">
                      <img
                        src="assets/images/female.png"
                        alt="handmade-craft-artist1"
                        className="img-responsive"
                        style={{ width: "160px", marginBottom: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Bhakshi Batra</h2>
                    <h5>Quality Assistant</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      As a Quality Assistant at way2digitizing, my primary focus
                      is to uphold the highest standards of accuracy and
                      craftsmanship in every embroidery digitizing project we
                      handle. I meticulously review each design file to ensure
                      that it meets our stringent quality criteria, from stitch
                      density and alignment to color accuracy and fabric
                      compatibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*happy customer sevtion start*/}
 <div className="hm_testimonial_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading hm_heading_01">
                <span className="hm_color">Our</span> happy customers
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="hm_testi_box">
                <div className="hm_test_img">
                  <img
                    src="assets/images/male.png"
                    alt="handmade-craft-customer1"
                    className="img-responsive"
                    style={{ width: "80px", height: "80px;" }}
                  />
                </div>
                <h2>Philen James</h2>
                <p>
                  Outstanding Work..We’ve been using way2digitizing for our
                  embroidery digitizing needs for over a year now, and we
                  couldn’t be happier. The quality of the digitizing is
                  top-notch, and the turnaround time is always quick. Our
                  designs come out perfect every time. Highly recommend!
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="hm_testi_box">
                <div className="hm_test_img">
                  <img
                    src="assets/images/female.png"
                    alt="handmade-craft-customer2"
                    className="img-responsive"
                    style={{ width: "120px", height: "120px;" }}
                  />
                </div>
                <h2>Melisa Broad</h2>
                <p>
                  Quick Turnaround and Perfect Results... [way2digitizing].. has
                  become our go-to for digitizing needs. Their quick turnaround
                  time and perfect results have made our production process so
                  much smoother. We appreciate their dedication to quality and
                  customer satisfaction.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="hm_testi_box">
                <div className="hm_test_img">
                  <img
                    src="assets/images/female.png"
                    alt="handmade-craft-customer3"
                    className="img-responsive"
                    style={{ width: "120px", height: "120px;" }}
                  />
                </div>
                <h2>Martina Carlo</h2>
                <p>
                  Highly Recommended….If you’re looking for reliable and skilled
                  embroidery digitizers, look no further than [way2digitizing].
                  Their work is always accurate, and their team is incredibly
                  helpful. We’ve recommended them to all our colleagues!{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

     

      {/*our partners section start*/}
      {/* <div className="hm_partners_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="hm_heading">Our clients</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="hm_partners_slider">
                <div className="owl-carousel owl-theme test_slider">
                  <div className="item">
                    <img
                      src="assets/images/partner1.png"
                      alt="handmade-craft-clients1"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner2.png"
                      alt="handmade-craft-clients2"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner3.png"
                      alt="handmade-craft-clients3"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner4.png"
                      alt="handmade-craft-clients4"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner5.png"
                      alt="handmade-craft-clients5"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner1.png"
                      alt="handmade-craft-clients1"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner2.png"
                      alt="handmade-craft-clients2"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner3.png"
                      alt="handmade-craft-clients3"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner4.png"
                      alt="handmade-craft-clients4"
                    />
                  </div>
                  <div className="item">
                    <img
                      src="assets/images/partner5.png"
                      alt="handmade-craft-clients5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Home;
