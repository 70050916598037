import React from 'react'
import Footer from './Footer'
import Topheader from './Topheader'
import Header from './Header'
import Menu from './Menu'

function About() {
  return (
    <>
     
  {/*banner start*/}
  <div className="hm_bread_wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h1>about Us</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active">about us</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  {/*About section start*/}
  <div className="hm_about_wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="hm_about_img">
            <img src="assets/images/about.jpg" alt className="img-responsive" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="hm_about_data">
            <h1>way2digitizing!</h1>
            <p>
            Way2digitizing was founded in April 2010 as a Embroidery Digitizing and Vector Art services.

</p>
<p>
Currently, our team comprises 25 full-time digitizers and 5 Quality Assistant  with the capacity to handle approximately 100 designs per day.
</p>
<p>
Our company specializes in embroidery and graphic digitizing, offering highly experienced custom embroidery and digitizing services. We are committed to focusing our efforts on achieving the highest level of customer satisfaction
.</p>
<p>
We primarily work with some of the most popular digitizing software, including Plus, Wilcom and Compucon. In addition to the Tajima DST format, we also provide your digitized files in various other formats.</p>            {/* <a href="#" className="hm_btn">read more</a> */}
          </div>
        </div>
      </div>
    </div>
  </div>

    </>
  )
}

export default About
